html,
body,
#root {
  width: 100%;
  min-height: 100%;
}

#root {
  background-image: url(./triangles-top.svg), url(./triangles-bottom.svg);
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: inherit;
}
